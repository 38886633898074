<template>
  <div class="navigator-container">
    <div class="navigator-content">
      <b-button
        class="prev-btn"
        @click="previousStep"
        :disabled="currentStepIndex === 0 || submitted"
        id="prelimPrev"
      >
        <b-icon
          class="prev-btn--icon"
          icon-pack="fas"
          icon="angle-left"
        ></b-icon>

        <span class="prev-btn--label">{{
          $t("af:preliminary.navigator.button.back")
        }}</span>
      </b-button>

      <div class="progress-bar" v-if="loaded">
        <p class="is-size-7 has-text-centered">
          <span class="has-text-weight-medium"
            >{{ currentStepIndex + 1 }}/{{ totalSteps }}
            {{ $t("af:preliminary.navigator.label.step") }}</span
          >
          <span class="has-text-grey-icon ml-1"
            >({{ stepTime(currentStepIndex) }}
            {{ $t("af:preliminary.navigator.label.minute") }})</span
          >
        </p>
        <b-progress
          type="is-green"
          :value="progress"
          size="is-small"
        ></b-progress>
      </div>

      <div class="progress-bar is-flex is-flex-direction-column" v-else>
        <p class="has-text-centered ml-auto mr-auto mb-2">
          <b-skeleton height="12px" width="128px" />
        </p>
        <b-skeleton height="12px" />
      </div>

      <b-button
        v-if="currentStepIndex + 1 !== totalSteps || isTypeSelectStep"
        id="prelimButtonNext"
        class="next-btn"
        type="is-blue"
        @click="nextStep"
        :disabled="!isPsd2Synced"
        >{{ $t("af:preliminary.navigator.button.next") }}</b-button
      >
      <b-button
        v-if="currentStepIndex + 1 === totalSteps && !isTypeSelectStep"
        id="prelimButtonCalculation"
        class="next-btn"
        type="is-blue"
        @click="submit"
        :disabled="submitted || !isPsd2Synced"
        >{{ $t("af:preliminary.navigator.button.calculation") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import i18n from "@/plugins/i18n";
import {
  APPLICATION_MODE,
  PRELIM_FIXED_STEPS,
  PRELIM_PSD2_FIXED_STEPS,
} from "@/utils/const";
import eventBus, { EVENTS } from "../../utils/eventBus";
import EmailConfirmationModal from "../EmailConfirmationModal";

export default {
  name: "PreliminaryNavigator",
  data() {
    return {
      loaded: true,
      PRELIM_FIXED_STEPS,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      steps: "preliminaryAssessment/steps",
      currentStepIndex: "preliminaryAssessment/currentStepIndex",
      stepTime: "preliminaryAssessment/stepTime",
      fixedSteps: "preliminaryAssessment/fixedSteps",
      isPsd2: "preliminaryAssessment/isPsd2",
      psd2Sync: "psd2/status",
    }),
    totalSteps() {
      return this.steps.length + this.fixedSteps; // including the first loan amount and term hard coded step
    },
    progress() {
      return ((this.currentStepIndex + 1) / this.totalSteps) * 100;
    },
    isPsd2Synced() {
      if (this.currentStepIndex === PRELIM_PSD2_FIXED_STEPS.SYNC) {
        if (this.isPsd2) {
          return (
            this.psd2Sync &&
            this.psd2Sync.hasSyncedBefore &&
            this.psd2Sync.lastSyncStatus === "SUCCESS"
          );
        }
      }
      return true;
    },
    isTypeSelectStep() {
      return false;
      // return this.currentStepIndex === PRELIM_FIXED_STEPS.TYPE_SELECT;
    },
  },
  created() {
    eventBus.$on(EVENTS.PRELIM_TYPE_CHANGED, this.onPrelimTypeChanged);
  },
  destroyed() {
    eventBus.$off(EVENTS.PRELIM_TYPE_CHANGED);
  },
  methods: {
    ...mapMutations({
      nextStepMut: "preliminaryAssessment/nextStep",
      previousStep: "preliminaryAssessment/previousStep",
    }),
    nextStep() {
      this.checkStep(async () => {
        this.nextStepMut();
      });
    },
    async submit() {
      this.track();
      this.checkStep(async () => {
        this.submitted = true;
        let success = await this.$store.dispatch(
          "preliminaryAssessment/submit"
        );
        await this.$store.dispatch("profile/updateStatus", {
          status: "PRELIMINARY",
          result: success ? "SUCCESS" : "RETRY",
        });

        await this.$store.dispatch(
          "profile/updateApplicationMode",
          APPLICATION_MODE.LOAN
        );

        await this.$router.push("/offers");
        await this.$store.dispatch("preliminaryAssessment/backToFirstStep");
      });
    },
    async checkStep(callback) {
      if (this.isPsd2 && this.isTypeSelectStep) {
        let isEmailConfirmedResponse = await this.$store.dispatch(
          "profile/isEmailConfirmed"
        );
        let isEmailConfirmed = isEmailConfirmedResponse.isEmailConfirmed;
        let currentEmail = isEmailConfirmedResponse.currentEmail;

        if (!isEmailConfirmed) {
          this.$buefy.modal.open({
            parent: this,
            component: EmailConfirmationModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            props: {
              currentEmail: currentEmail,
            },
          });
          return;
        }
      }

      this.$store.dispatch("preliminaryAssessment/validate");

      if (
        this.$store.getters["preliminaryAssessment/checkStep"](
          this.currentStepIndex
        )
      ) {
        callback();
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t(
            "af:preliminary.message.validator.all_fields_required"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    onPrelimTypeChanged(started) {
      this.loaded = !started;
    },
    track() {
      let data = this.$store.state.preliminaryAssessment.data;
      this.$gtag.query("set", "user_properties", data);
      this.$gtag.event("preliminary_assessment");
    },
  },
};
</script>
